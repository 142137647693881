
  import SelectedFilters from './SelectedFilters';
  import ProductCardInfo from '../productCard/ProductCardInfo.vue';
  import dataLayerMixin from './data.layer.list.mixin';
  import checkProductsPrices from '../../plugins/check.products.prices';

  export default {
    mixins: [dataLayerMixin, checkProductsPrices],
    data() {
      return {
        componentCurrentPage: this.currentPage,
      };
    },
    mounted() {
      try {
        const dataLayer = window.dataLayer;
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        dataLayer.push({
          event: 'view_item_list',
          ecommerce: {
            items: this.productsList.map(this.productItemDataLayer),
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    components: {
      ProductCardInfo,
      SelectedFilters,
    },
    props: {
      limit: Number,
      total: Number,
      pagination: Object,
      errorText: String,
      search: String,
      loading: Boolean,
      products: Object,
      mainClass: String,
      suppliers: { type: Object | Array, default: () => [] },
      productsList: Array,
      facets: Array,
      groupsWithFacets: Array,
      productComponentProps: Object,
      currentPage: {
        type: Number,
        default: () => 1,
      },
      selectedSuppliers: {
        type: Object,
        default: () => ({}),
      },
      selectedFacets: Object,
    },
    computed: {
      filteredFacetGroups() {
        return this.groupsWithFacets.filter((g) => g && g.facets.some((f) => f.activeProductsCount > 0));
      },
    },
    methods: {
      onRemoveFilter(name) {
        let next = this.$route.path;
        const query = JSON.parse(JSON.stringify(this.$route.query));
        query.page = 1;
        if (query[name]) delete query[name];
        next = next.replace(`/${name}`, '');
        if (next.split('/').length <= 2) next = '/';
        this.$router.push({ path: next, query });
      },
      onRemoveCloseout() {
        this.onRemoveFilter('closeout');
      },
      onRemoveNoMinimum() {
        this.onRemoveFilter('no-minimum');
      },
    },
  };
