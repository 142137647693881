import { render, staticRenderFns } from "./Categories1.vue?vue&type=template&id=393e90a7&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform"
import script from "./Categories1.vue?vue&type=script&lang=js"
export * from "./Categories1.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductListDetails: require('/codebuild/output/src138728670/src/client/components/filters/ProductListDetails.vue').default,SelectedFilters: require('/codebuild/output/src138728670/src/client/components/filters/SelectedFilters.vue').default,ProductsFilters: require('/codebuild/output/src138728670/src/client/components/filters/ProductsFilters.vue').default,FiltersModal: require('/codebuild/output/src138728670/src/client/components/modals/FiltersModal.vue').default,SortProductList: require('/codebuild/output/src138728670/src/client/components/filters/SortProductList.vue').default,ProductList: require('/codebuild/output/src138728670/src/client/components/filters/ProductList.vue').default})
