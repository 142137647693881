
  export default {
    name: 'ProductListDetails',
    props: {
      total: Number,
      limit: Number,
      debugDetails: Object | Array,
      componentCurrentPage: Number,
    },
    data() {
      return {
        debugDetailsInfo: this.debugDetails.map((e) => ({ ...e, spent: `${e.spent}ms` })),
      };
    },
  };
