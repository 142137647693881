
  import BlogPostCard from './BlogPostCard';
  import { formatPageToPost } from '@/utils/pages';

  export default {
    components: {
      BlogPostCard,
    },
    props: {
      categories: Array,
    },
    data() {
      return {
        posts: [],
        loading: true,
      };
    },
    async mounted() {
      try {
        const [categoryId, ...categories] = (this.categories || []).map((c) => (typeof c === 'string' ? c : c._id));
        if (categoryId) {
          const { data = [] } = await this.$api.stores.getStorePagesByCategory(categoryId, { categories });
          this.posts = data.map((page) => formatPageToPost(page));
        }
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
  };
