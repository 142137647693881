export default {
  computed: {
    isMobile() {
      return false; //ignore
      if (process.client) {
        const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

        return (
          toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
          }) || window.innerWidth <= 360
        );
      }
    },
  },
};
