
export default {
  name: 'FiltersModal',
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    showFilters() {
      this.showModal = true;
      this.$refs.filtersmodal.show();
      localStorage.setItem('SHOW_FILTERS', true);
    },
    onClose() {
      localStorage.removeItem('SHOW_FILTERS');
      this.showModal = false;
      this.$nextTick(() => this.$refs.filtersmodal.hide());
    },
  },
  mounted() {
    if (localStorage.getItem('SHOW_FILTERS')) {
      this.showModal = true;
      this.$refs.filtersmodal.show();
    }
  },
};
