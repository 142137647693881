
  import isCloseOut from '@/plugins/isCloseOut';
  import isNoMinimum from '@/plugins/isNoMinimum';

  export default {
    props: {
      facets: {
        type: Array,
        default: () => [],
      },
      groupsWithFacets: {
        type: Array,
        default: () => [],
      },
      selectedFacets: {
        type: Object,
        default: () => ({}),
      },
      selectedSuppliers: {
        type: Object,
        default: () => ({}),
      },
      suppliers: {
        type: Object | Array,
        default: () => [],
      },
      search: String,
    },
    mixins: [isCloseOut, isNoMinimum],
    computed: {
      selectedInventory() {
        const filters = {
          'in-stock': 'In Stock',
          'out-of-stock': 'Out of Stock',
        };
        const { inventory } = this.$route.query || {};
        const selectedInventory = [];
        if (inventory) {
          if (Array.isArray(inventory)) {
            inventory.forEach((path) => selectedInventory.push({ path, label: filters[path.toLowerCase()] }));
          } else {
            selectedInventory.push({ path: inventory, label: filters[inventory.toLowerCase()] });
          }
        }
        return selectedInventory;
      },
      selectedFacetsWithNames() {
        const facetsIds = [].concat.apply([], Object.values(this.selectedFacets));
        let facetsObjects = [].concat.apply(
          [],
          this.groupsWithFacets.map((group) => [].concat.apply([], group.facets))
        );

        if (!facetsObjects.length && this.facets) {
          facetsObjects = this.facets || [];
        }
        if (facetsIds[0] && typeof facetsIds[0] === 'object') {
          return facetsIds
            .map((id) => {
              const r = Object.keys(id);
              return facetsObjects.filter((f) => r.includes(f._id));
            })
            .reduce((a, b) => [...a, ...b], [])
            .filter((f) => f);
        }
        return facetsIds
          .map((id) => {
            return facetsObjects.find((f) => f._id === id);
          })
          .filter((f) => f);
      },
      suppliersWithProductsCount() {
        const suppliers = this.suppliers
          .filter((supplier) => supplier.productCount > 0)
          .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1));
        if (!suppliers.length) {
          return Object.keys(this.selectedSuppliers).map((psCode) => ({ psCode, name: psCode }));
        }
        return suppliers;
      },
      hasFilters() {
        return (
          this.selectedFacetsWithNames.length > 0 ||
          Object.keys(this.selectedSuppliers).length > 0 ||
          this.isCloseOut ||
          this.isNoMinimum ||
          this.search
        );
      },
    },
    methods: {
      removeFromSelectedInventory(item) {
        let next = this.$route.path;
        const query = JSON.parse(JSON.stringify(this.$route.query));
        Object.assign(query, {
          inventory: this.selectedInventory.filter((inventory) => item.path !== inventory.path).map((e) => e.path),
        });
        if (!query.inventory.length) delete query.inventory;
        this.$router.push({ path: next, query });
      },
      onRemoveCloseout() {
        if (this.$refs.closeOut) this.$refs.closeOut.onChange(false);
        if (this.$refs.closeOut1) this.$refs.closeOut1.onChange(false);
        this.$emit('onRemoveCloseout');
      },
      onRemoveNoMinimum() {
        // if (this.$refs.noMinimum) this.$refs.noMinimum.onChange(false);
        // if (this.$refs.noMinimum1) this.$refs.noMinimum1.onChange(false);
        this.$emit('onRemoveNoMinimum');
      },
      removeFromSelectedFacets(facetId) {
        for (let [key, facets] of Object.entries(this.selectedFacets)) {
          if (typeof facets === 'object') {
            facets = Object.keys(facets);
            if (facets.includes(facetId)) {
              this.selectedFacets[key][facetId] = false;
              return delete this.selectedFacets[key][facetId];
            }
          } else {
            if (facets.includes(facetId)) {
              const index = facets.findIndex((f) => f === facetId);
              return this.$set(this.selectedFacets[key], index, false);
            }
          }
        }
      },
    },
  };
