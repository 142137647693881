
  import isCloseOut from '@/plugins/isCloseOut';
  import ProductCardInfo from '../productCard/ProductCardInfo';
  import BlogPosts from '../blog/BlogPostList';
  import ProductList from '../filters/ProductList';
  import propsinterpolationMixin from '@/plugins/propsinterpolationMixin';
  import deviceMixin from '@/plugins/deviceMixin';

  export default {
    mixins: [isCloseOut, propsinterpolationMixin, deviceMixin],
    data() {
      const selectedSuppliers = {};
      let t = [];
      try {
        t = JSON.parse(JSON.stringify(this.filterSuppliers));
      } catch (e) {}
      if (!Array.isArray(t)) t = [t];
      try {
        t.map((i) => this.suppliers.find((s) => s.psCode.toLowerCase() === i.toLowerCase()).psCode).forEach((el) => {
          selectedSuppliers[el] = true;
        });
      } catch (e) {
        t.forEach((supplier) => (selectedSuppliers[supplier] = true));
      }
      return {
        loaded: false,
        searchVal: this.search || '',
        componentCurrentPage: this.currentPage,
        selectedSuppliers,
        activeCats: [],
        sortNames: [
          { text: 'Name: A-Z', value: 1 },
          { text: 'Name: Z-A', value: -1 },
        ],
        sortPrices: [
          { text: 'Price: Low - High', value: 1 },
          { text: 'Price: High - Low', value: -1 },
        ],
        hover: false,
        selectedFacets: this.facetGroupMap,
      };
    },
    props: {
      categories: Object | Array,
      debugDetails: Object | Array,
      componentProps: Object,
      parseResources: {
        type: Array,
        default: () => [],
      },
      totalInventory: {
        type: Array,
        default: () => [],
      },
      facetsFilter: {
        type: Object,
        default: () => ({}),
      },
      products: Object,
      product: Object,
      pagination: Object,
      productsList: {
        type: Array,
        default: () => [],
      },
      total: Number,
      limit: Number,
      totalCloseOut: Number,
      totalNoMinimum: Number,
      currentPage: Number,
      loading: Boolean,
      currentCategory: Object,
      mainCategory: Object,
      filterSuppliers: Array | String,
      priceRange: Array,
      rangeData: Array,
      groupsWithFacets: Array,
      facets: Array,
      selectedSort: Object,
      sortItems: Array,
      search: String,
      facetGroupMap: Object,
      suppliers: {
        type: Array,
        required: true,
      },
    },
    watch: {
      selectedFacets: {
        handler: function(val) {
          if (
            this.allFacets(val).length === 1 &&
            !this.$route.params.slug &&
            !this.search &&
            Object.keys(this.$route.query).length === 0
          ) {
            this.goToCategories(val);
          } else {
            this.$emit('selectedFacets', val);
          }
        },
        deep: true,
      },
    },
    computed: {
      postsCategories() {
        return [this.currentCategory._id];
      },
      filteredFacetGroups() {
        return this.groupsWithFacets.filter((g) => g && g.facets.some((f) => f.activeProductsCount > 0));
      },
    },
    methods: {
      changeCurrentPage(page) {
        this.componentCurrentPage = page;
        window.scrollTo(0, 0);
        this.$emit('changeCurrentPage', page);
      },
      allFacets(selectedFacets) {
        return [].concat.apply([], Object.values(selectedFacets)).filter((f) => f);
      },
      onSupplierFilter() {
        const selectedItems = Object.keys(this.selectedSuppliers).filter((sup) => this.selectedSuppliers[sup]);
        if (
          selectedItems.length === 1 &&
          !this.$route.params.slug &&
          !this.search &&
          !Object.keys(this.$route.query).length
        ) {
          let link = `/cat/${this.currentCategory.slug}`;
          if (selectedItems[0]) {
            link += `/supplier/${selectedItems[0].toLowerCase()}`;
          }
          this.$router.push(link);
        } else {
          this.$emit('selectedSupplier', selectedItems);
        }
      },
      goToCategories(selectedFacets) {
        const allFacets = this.allFacets(selectedFacets);
        for (const key of Object.keys(selectedFacets)) {
          if (_.difference(selectedFacets[key], allFacets).length === 0) {
            const facet = this.facets.find((f) => f._id === allFacets[0]);
            const group = this.groupsWithFacets.find((g) => g._id === facet.facetGroup);
            this.$router.push(`/cat/${this.currentCategory.slug}/${group.slug}/${facet.slug}`);
          }
        }
      },
      removeSearchQuery() {
        this.$emit('reloadSearch');
      },
    },
    components: {
      ProductListDetails: () => import('../filters/ProductListDetails'),
      SortProductList: () => import('../filters/SortProductList'),
      BlogPosts,
      ProductsFilters: () => import('../filters/ProductsFilters'),
      ProductList,
      ProductCardInfo,
    },
  };
